import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { sendCallMeBack } from '../../api_v2/public/messaging';

const CallMeModal = ({ showModal, handleClose, addNewCallMe, products }) => {
    const [newCallMe, setNewCallMe] = useState({
        fullname: '',
        number: '',
        email: '',
        message: '',
    });
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(''); 
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewCallMe({ ...newCallMe, [name]: value });
    };

    const validateForm = () => {
        let formIsValid = true;
        let errors = {};

        if (!newCallMe.fullname) {
            formIsValid = false;
            errors['fullname'] = 'Name cannot be empty';
        }

        if (!newCallMe.email) {
            formIsValid = false;
            errors['email'] = 'Email cannot be empty';
        } else if (!/\S+@\S+\.\S+/.test(newCallMe.email)) {
            formIsValid = false;
            errors['email'] = 'Email is not valid';
        }

        if (!newCallMe.number) {
            formIsValid = false;
            errors['number'] = 'Number cannot be empty';
        }

        if (!newCallMe.message) {
            formIsValid = false;
            errors['message'] = 'Message cannot be empty';
        }

        setErrors(errors);
        return formIsValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const isFormValid = validateForm();

        if (isFormValid) {
            sendCallMeBack(newCallMe)
                .then(() => {
                    setShowSuccessMessage(true);
                    setTimeout(() => { 
                        setShowSuccessMessage(false);
                        handleClose(); 
                    }, 3000);
                })
                .catch(error => {
                    setShowFailureMessage(true);
                    setErrorMessage(error.message || 'An unexpected error occurred.');
                    console.error('Error submitting form', error);
                });
        }
    };

    return (
        <Modal size="lg" className="modal-call-me" show={showModal} onHide={handleClose}>
            <div className="modal-call-body">
                <Modal.Body className="modal-call-body">
                    <Modal.Title className="text-center pb-2"><h1 className="modal-title fs-1 text-gg-blue">Send Us Your Details And We'll Call You Back</h1></Modal.Title>
                    {showSuccessMessage && <Alert variant="success">Your submission was successful! We'll call you back soon.</Alert>}
                    {showFailureMessage && <Alert variant="error">Your submission was unsuccessful! {errorMessage}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <Form.Group className="mb-3" controlId="fullname">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="fullname"
                                    value={newCallMe.fullname}
                                    placeholder="Your name"
                                    onChange={handleInputChange}
                                    required />
                                {errors.fullname && <div style={{ color: 'red' }}>{errors.fullname}</div>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="number">
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="number"
                                    placeholder="Your contact number"
                                    value={newCallMe.number}
                                    onChange={handleInputChange}
                                    required />
                                {errors.number && <div style={{ color: 'red' }}>{errors.number}</div>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={newCallMe.email}
                                    placeholder="Your email"
                                    onChange={handleInputChange}
                                    required />
                                {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="message">
                                <Form.Label>How Can We Help?</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="message"
                                    rows="6"
                                    placeholder="Tell us how we can help you"
                                    value={newCallMe.message}
                                    onChange={handleInputChange}
                                    required />
                                {errors.message && <div style={{ color: 'red' }}>{errors.message}</div>}
                            </Form.Group>
                        </div>
                        <Modal.Footer>
                            <Button className="gg-bg-blue" type="submit">Get A Call Back</Button>
                            <Button variant="danger" onClick={handleClose}>Close</Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </div>
        </Modal>
    );
};

export default CallMeModal;
