import axios from "axios";
import localSessionApi, { token_key } from '../../utils/local_session';
import NProgress from 'nprogress'

const PROXY_ENDPOINT = process.env.REACT_APP_PROXY_V2_ENDPOINT

NProgress.configure({ showSpinner: false });
axios.interceptors.request.use((config) => {
    NProgress.start();
    return config;
}, (error) => {
    NProgress.done();
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    NProgress.done();
    return response;
}, (error) => {
    NProgress.done();
    return Promise.reject(error);
});

export function doPrivatePostRequest(_payload){
    const PROXY_TOKEN = localSessionApi.getWithExpiry(token_key)
    const config = {
        method: 'post',
        url: `${PROXY_ENDPOINT}`,
        timeout: 40000,
        headers: { 
            'Content-Type': 'application/json',
            'GO-GAS-JWT': PROXY_TOKEN
        },
        data : _payload
    };
    return axios(config)
}

export function doPrivateUrlPostRequest(_url, _payload){
    const PROXY_TOKEN = localSessionApi.getWithExpiry(token_key)
    const config = {
        method: 'post',
        url: `${PROXY_ENDPOINT}/${_url}`,
        timeout: 40000,
        headers: { 
            'Content-Type': 'application/json',
            'GO-GAS-JWT': PROXY_TOKEN
        },
        data : _payload
    };
    return axios(config)
}

export function doPrivateDownloadPostRequest(_path, _payload){
    const PROXY_TOKEN = localSessionApi.getWithExpiry(token_key)
    const config = {
        method: 'post',
        url: `${PROXY_ENDPOINT}/download/${_path}`,
        responseType: 'blob',
        timeout: 80000,
        headers: { 
            'Content-Type': 'application/json',
            'GO-GAS-JWT': PROXY_TOKEN
        },
        data : _payload
    };
    return axios(config)
}