import React from "react";
import { Navigate } from "react-router-dom";
import Layout from "./Layout";
import localSessionApi, {token_key, user_data_key} from "./local_session";


const DriverRoutes = () => {
    let hasToken = false
    let user_role = 'Not Set'
    const jwt = localSessionApi.getWithExpiry(token_key)
    if(jwt !== null){
        hasToken = true;
        user_role = localSessionApi.getWithExpiry(user_data_key)['role']
    }
    let auth = {'hasToken': hasToken}
    return(
        auth.hasToken && user_role === "Driver" ? <Layout/> : <Navigate to='*' />
    )
}
export default DriverRoutes