import CryptoJS from "crypto-js";
const SECRET_PASS = process.env.REACT_APP_CRYPTO_SECRET

const Sec = {
    encryptData: (_text) => {
        const data = CryptoJS.AES.encrypt(JSON.stringify(_text), SECRET_PASS).toString();
        return data
    },
    decryptData: (_text) => {
        const bytes = CryptoJS.AES.decrypt(_text, SECRET_PASS);
        const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return data
    }
}


export default Sec