import React, { Suspense} from 'react';
import { BrowserRouter as Router, Routes, Route, useHistory, useLocation } from 'react-router-dom'
import UnderMaintenancePage from '../pages/publicPages/underMaintenancePage';

const OfflineRoutes = () => {
return <>
<Routes>
    <Route element={<UnderMaintenancePage/>} exact path='/' />
</Routes>
</>
}

export default OfflineRoutes