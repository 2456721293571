import { doPrivatePostRequest } from "./requests"

export const doGlobalSearch = (_text) => {
    const payload = {
        'fn': 'doGlobalSearch',
        'params': {
            'text': _text
        }
    }
    return doPrivatePostRequest(payload)
}