import Sec from "../functions/advanced_math"
export const token_key = 'GO-GAS-JWT'
export const user_data_key = 'GO-GAS-AUTH-USER'
export const theme_key = 'GG-THEME'


const localSessionApi = {
    setUser: (_user) => {
        console.warn(`localSessionApi -> setUser / TO BE REMOVED`)
        sessionStorage.setItem("user", JSON.stringify(_user));
    },
    getUser: () => {
        console.warn(`localSessionApi -> getUser / TO BE REMOVED`)
        const user = sessionStorage.getItem("user");
        if(user === null){return null;}
        else{
            try {
                return JSON.parse(user)
            } catch (error) {return null;}
        }
    },
    setUserApiToken: (_token) => {
        console.warn(`localSessionApi -> setUserApiToken / TO BE REMOVED`)
        sessionStorage.setItem("GO-GAS-JWT", _token);
    },
    getUserApiToken: (_token) => {
        console.warn(`localSessionApi -> getUserApiToken / TO BE REMOVED`)
        const token = sessionStorage.getItem("GO-GAS-JWT");
        if(token === null){return null;}
        else{return token}
    },
    getUserRole: (_role) => {
        console.warn(`localSessionApi -> getUserRole / TO BE REMOVED`)
        const user = sessionStorage.getItem("user");
        if(user === null){return null;}
        else{
            try {
                return JSON.parse(user)['role']
            } catch (error) {return null;}
        }
    },
    getUserEmail: () => {
        console.warn(`localSessionApi -> getUserEmail / TO BE REMOVED`)
        const user = sessionStorage.getItem("user");
        if(user === null){return null;}
        else{
            try {
                return JSON.parse(user)['email']
            } catch (error) {return null;}
        }
    },
    getUserId: () => {
        console.warn(`localSessionApi -> getUserId / TO BE REMOVED`)
        const user = sessionStorage.getItem("user");
        if(user === null){return null;}
        else{
            try {
                return JSON.parse(user)['_id']
            } catch (error) {return null;}
        }
    },
    getUserAvatar: () => {
        console.warn(`localSessionApi -> getUserAvatar / TO BE REMOVED`)
        const user = sessionStorage.getItem("user");
        if(user === null){return null;}
        else{
            try {
                return JSON.parse(user)['avatar']
            } catch (error) {return null;}
        }
    },
    setUserAvatar: (_avatar_string) => {
        console.warn(`localSessionApi -> setUserAvatar / TO BE REMOVED`)
        let user = sessionStorage.getItem("user");
        user = JSON.parse(user)
        user['avatar'] = _avatar_string
        const json_user = JSON.stringify(user)
        sessionStorage.setItem('user', json_user)
    },
    // NEW STANDARD
    setWithExpiry(key, value) {
        try {
            let expireDate = new Date()
            expireDate.setDate(expireDate.getDate() + 25);
            const item = {
                value: value,
                expiry: expireDate,
            }
            localStorage.setItem(key, Sec.encryptData(JSON.stringify(item)))
        } catch (error) {
            console.error(error)
            return null
        }
    },
    getWithExpiry(key) {
        try {
            const itemStr = localStorage.getItem(key)
            if (!itemStr) {
                return null
            }
            const item = JSON.parse(Sec.decryptData(itemStr))
            const now = new Date()
            if (now.getTime() > item.expiry) {
                localStorage.removeItem(key)
                return null
            }
            return item.value
        } catch (error) {
            if(key === user_data_key){
                window.location.replace('/')
            }
            console.error(error)
            return null
        }
    },
}

export default localSessionApi