import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useHistory, useLocation } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google';
import TagManager from 'react-gtm-module'
import { Button } from 'react-bootstrap';
import { sendCallMeBack } from './api_v2/public/messaging';
import CallMeModal from './components/modals/call-me-modal';
import OfflineRoutes from './utils/OfflineRoutes';
import AllRoutes from './utils/AllRoutes';
import './App.css'
import './nprogress.css'

function App() {  
  const ENV = process.env.REACT_APP_ENV
  const [appLoaded, setAppLoaded] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [newCallMe, setNewCallMe] = useState({});
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  async function addNewCallMe(newCallMe) {
    const { name, surname, mobilePhone, email, message } = newCallMe;
  
    try {
      const response = await sendCallMeBack(name, surname, mobilePhone, email, message);
  
      if (response.data.Result === 'SUCCESS') {
        console.log('Call Me request sent successfully.');
      } else {
        console.error('Call Me request failed with response:', response.data);
      }
    } catch (error) {
      console.error('An error occurred while sending the Call Me request:', error);
    }
  }

  useEffect(() => {
    if(!appLoaded){
      setAppLoaded(false)
      setTimeout(() => {updateChatAppBtn()}, 500)
    }
  }, [appLoaded])

  function updateChatAppBtn(){
    const eleId = 'btnOpenChatApp'
    try {
      const ele = document.getElementById(eleId)
      ele.style.bottom = '15px'
  
      setTimeout(() => {
        ele.style.bottom = '15px'
      }, 3000)
    } catch (error) {
      setTimeout(() => {
        const ele = document.getElementById(eleId)
        ele.style.bottom = '15px'
      }, 1500)
    }
  }

  
  
  return (
    <GoogleOAuthProvider clientId="94629727042-kjbu2lb0q8146t9al2fmphfc2atpepu6.apps.googleusercontent.com">
    <div className="App">
      <Router>
        {['DEV', 'UAT', 'PROD'].includes(ENV)?
          <AllRoutes/>
          :
          <OfflineRoutes/>
        }
      </Router>
      <CallMeModal addNewCallMe={addNewCallMe} showModal={showModal} handleClose={handleCloseModal} />
            
      <Button id='requestACallBtn' className="btn gg-bg-blue modal-toggle-button" onClick={handleOpenModal}>
        Request A Call
      </Button>
    </div>
    </GoogleOAuthProvider>
  );
}

export default App;