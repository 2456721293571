import React, {useEffect, useState} from 'react'
import { Link, useNavigate } from "react-router-dom";
import Search from './Search'
import Notifications from './Notifications'
import localSessionApi, {theme_key, user_data_key} from '../../utils/local_session'
import GitInfo from 'react-git-info/macro';
import { IoShareSocialSharp, IoCopyOutline } from "react-icons/io5";
import { makeToast } from '../../functions/toats'


const Header = () => {
const navigate = useNavigate();
const gitInfo = GitInfo();
const pfp_alt_link = 'https://gogas.ams3.cdn.digitaloceanspaces.com/users/default.png'
const [mounted, setMounted] = useState(false)
const [avatarId, setAvatarId] = useState('default')
const [userDetails, setUserDetails] = useState('')
const [avatar, setAvatar] = useState('default')
const [userAddress, setUserAddress] = useState([])
const [isLoadingUserDetails, setIsLoadingUserDetails] = useState(true)
const [gitHash, setGitHash] = useState('')
const [userRole, setUserRole] = useState('')
const [affiliateName, setAffiliateName] = useState('')
const [theme, setTheme] = useState('light')

useEffect(() => {
    if(!mounted){
        setMounted(true)
        setUserAvatar()
        setGitHash(gitInfo.commit.shortHash)
        const user = localSessionApi.getWithExpiry(user_data_key)
        setUserRole(user['role'])
        getTheme()
        // if(user['role'].toLowerCase() === 'affiliate'){
        //     setAffiliateName(user['associated_affiliate']['name'])
        // }
    }
}, [mounted])

function onProfileClick(){
    const user = localSessionApi.getWithExpiry(user_data_key)
    if(user['role'].toLowerCase() === 'admin'){
        navigate('/backend-staff-my-details')
    }else if(user['role'].toLowerCase() === 'customer'){
        navigate('/my-details')
    }
    else if(user['role'].toLowerCase() === 'affiliate'){
        navigate('/affiliate-my-details')
    }else{
        navigate('/my-details')
    }
}

function getTheme(){
    const saved_theme = localSessionApi.getWithExpiry(theme_key)
    if(saved_theme === null){
        localSessionApi.setWithExpiry(theme_key, 'light')
        return;
    }
    onSetThemeClick(saved_theme)
}

function onSetThemeClick(_theme){
    setTheme(_theme)
    var bodyElement = document.getElementById('html-struc');
    
    // Checking if the body element exists to avoid errors
    if(bodyElement) {
        // Updating the 'data-layout-color' attribute to the new color
        bodyElement.setAttribute('data-theme', _theme);
        localSessionApi.setWithExpiry(theme_key, _theme)
    } else {
        console.error('Body element not found!');
    }
}

function setUserAvatar(){
    const user_data = localSessionApi.getWithExpiry('GO-GAS-AUTH-USER')
    setUserDetails(user_data)
    setAvatarId(user_data['avatar'])
}

function onLogoutClick(){
    sessionStorage.clear()
    localStorage.clear()
    window.location.pathname="/login"
}

return (
<>
<header className="container-fluid d-flex py-6 mb-4 top-nav">
    {['affiliate'].includes(userRole)?
    <div className='d-flex align-items-center'>
        <h3 className='text-white mb-0'>
            <span className='me-2'>{affiliateName}</span>
        </h3>
    </div>
    : <Search></Search>}
    
    <div className="d-flex align-items-center ms-auto me-n1 me-lg-n2">
        <div className="dropdown" id="themeSwitcher">
            <a href="/" className="dropdown-toggle no-arrow d-flex align-items-center justify-content-center bg-white rounded-circle shadow-sm mx-1 mx-lg-2 w-40px h-40px link-secondary"
                role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,10">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={18} width={18}>
                <g>
                    <path d="M12,4.64A7.36,7.36,0,1,0,19.36,12,7.37,7.37,0,0,0,12,4.64Zm0,12.72A5.36,5.36,0,1,1,17.36,12,5.37,5.37,0,0,1,12,17.36Z" style={{ fill: "currentColor" }}/>
                    <path d="M12,3.47a1,1,0,0,0,1-1V1a1,1,0,0,0-2,0V2.47A1,1,0,0,0,12,3.47Z" style={{ fill: "currentColor" }}/>
                    <path d="M4.55,6a1,1,0,0,0,.71.29A1,1,0,0,0,6,6,1,1,0,0,0,6,4.55l-1-1A1,1,0,0,0,3.51,4.93Z" style={{ fill: "currentColor" }}/>
                    <path d="M2.47,11H1a1,1,0,0,0,0,2H2.47a1,1,0,1,0,0-2Z" style={{ fill: "currentColor" }}/>
                    <path d="M4.55,18l-1,1a1,1,0,0,0,0,1.42,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l1-1A1,1,0,0,0,4.55,18Z" style={{ fill: "currentColor" }}/>
                    <path d="M12,20.53a1,1,0,0,0-1,1V23a1,1,0,0,0,2,0V21.53A1,1,0,0,0,12,20.53Z" style={{ fill: "currentColor" }}/>
                    <path d="M19.45,18A1,1,0,0,0,18,19.45l1,1a1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.42Z" style={{ fill: "currentColor" }}/>
                    <path d="M23,11H21.53a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" style={{ fill: "currentColor" }}/>
                    <path d="M18.74,6.26A1,1,0,0,0,19.45,6l1-1a1,1,0,1,0-1.42-1.42l-1,1A1,1,0,0,0,18,6,1,1,0,0,0,18.74,6.26Z" style={{ fill: "currentColor" }}/>
                </g>
                </svg>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
                <li>
                    <button onClick={() => onSetThemeClick('light')} type="button"className="dropdown-item active" data-theme-value="light">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="me-2" height={18} width={18}>
                        <g>
                            <path d="M12,4.64A7.36,7.36,0,1,0,19.36,12,7.37,7.37,0,0,0,12,4.64Zm0,12.72A5.36,5.36,0,1,1,17.36,12,5.37,5.37,0,0,1,12,17.36Z" style={{ fill: "currentColor" }}/>
                            <path d="M12,3.47a1,1,0,0,0,1-1V1a1,1,0,0,0-2,0V2.47A1,1,0,0,0,12,3.47Z" style={{ fill: "currentColor" }}/>
                            <path d="M4.55,6a1,1,0,0,0,.71.29A1,1,0,0,0,6,6,1,1,0,0,0,6,4.55l-1-1A1,1,0,0,0,3.51,4.93Z" style={{ fill: "currentColor" }}/>
                            <path d="M2.47,11H1a1,1,0,0,0,0,2H2.47a1,1,0,1,0,0-2Z" style={{ fill: "currentColor" }}/>
                            <path d="M4.55,18l-1,1a1,1,0,0,0,0,1.42,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l1-1A1,1,0,0,0,4.55,18Z" style={{ fill: "currentColor" }}/>
                            <path d="M12,20.53a1,1,0,0,0-1,1V23a1,1,0,0,0,2,0V21.53A1,1,0,0,0,12,20.53Z" style={{ fill: "currentColor" }}/>
                            <path d="M19.45,18A1,1,0,0,0,18,19.45l1,1a1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.42Z" style={{ fill: "currentColor" }}/>
                            <path d="M23,11H21.53a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" style={{ fill: "currentColor" }}/>
                            <path d="M18.74,6.26A1,1,0,0,0,19.45,6l1-1a1,1,0,1,0-1.42-1.42l-1,1A1,1,0,0,0,18,6,1,1,0,0,0,18.74,6.26Z" style={{ fill: "currentColor" }}/>
                        </g>
                        </svg>
                        Light mode
                    </button>
                </li>
                <li>
                    <button onClick={() => onSetThemeClick('dark')} type="button" className="dropdown-item" data-theme-value="dark">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="me-2" height={18} width={18}>
                        <path d="M19.57,23.34a1,1,0,0,0,0-1.9,9.94,9.94,0,0,1,0-18.88,1,1,0,0,0,.68-.94,1,1,0,0,0-.68-.95A11.58,11.58,0,0,0,8.88,2.18,12.33,12.33,0,0,0,3.75,12a12.31,12.31,0,0,0,5.11,9.79A11.49,11.49,0,0,0,15.61,24,12.55,12.55,0,0,0,19.57,23.34ZM10,20.17A10.29,10.29,0,0,1,5.75,12a10.32,10.32,0,0,1,4.3-8.19A9.34,9.34,0,0,1,15.59,2a.17.17,0,0,1,.17.13.18.18,0,0,1-.07.2,11.94,11.94,0,0,0-.18,19.21.25.25,0,0,1-.16.45A9.5,9.5,0,0,1,10,20.17Z"
                            style={{ fill: "currentColor" }}/>
                        </svg>
                        Dark mode
                    </button>
                </li>
            </ul>
        </div>
        
        <div className="vr bg-gray-700 mx-2 mx-lg-3" />

        <div className="dropdown">
            <a href="/" className="dropdown-toggle no-arrow d-flex align-items-center justify-content-center bg-white rounded-circle shadow-sm mx-1 mx-lg-2 w-40px h-40px"
                role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false"  data-bs-offset="0,10">
                <div className="avatar avatar-circle avatar-sm avatar-online">
                    <img src={`https://gogas.ams3.cdn.digitaloceanspaces.com/users/${avatarId}.png`} id='topNavPfp' alt={pfp_alt_link} className="avatar-img" width={40} height={40}/>
                </div>
            </a>
            <div className="dropdown-menu">
                <div className="dropdown-item-text">
                    <div className="d-flex align-items-center">
                        <div className="avatar avatar-sm avatar-circle">
                            <img src={`https://gogas.ams3.cdn.digitaloceanspaces.com/users/${avatarId}.png`} id='topNavPfp2' alt={pfp_alt_link} className="avatar-img" width={40} height={40}/>
                        </div>
                        <div className="flex-grow-1 ms-3">
                            <h4 className="mb-0"></h4>
                            <p className="card-text">Hello, {userDetails.name} </p>
                        </div>
                    </div>
                </div>

                <hr className="dropdown-divider" />
                <div className="dropdown dropend"></div>
                
                <a onClick={() => onProfileClick()} className="dropdown-item mp">Profile &amp; account</a>
                {userDetails['role'] === 'Admin'?
                    <Link to={`/general-settings`} className="dropdown-item">Settings</Link>
                :null}
                <hr className="dropdown-divider" />
                <a className="dropdown-item mb-0 pb-0 mp" onClick={() => onLogoutClick()}>Sign out</a>
                <a className="dropdown-item fs-5 pt-1 opacity-80">version: &nbsp;<b>{gitHash}</b></a>
            </div>
        </div>
    </div>
</header>
</>
)}

export default Header