import { doPublicPostRequest } from "./requests"

export const sendCallMeBack = (_data) => {
    const payload = {
        'fn': 'sendCallMeBack',
        'params': {
            'fullname': _data['fullname'],
            'number': _data['number'],
            'email': _data['email'],
            'message': _data['message']
        }
    }
    return doPublicPostRequest(payload)
}