import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
toast.configure();

export const makeToast = (_type, _message) => {
    switch (_type) {
    case 'info':
        toast.info(_message, { position: "top-right", autoClose: 5000, theme: "colored"});
        break;
    case 'success':
        toast.success(_message, { position: "top-right", autoClose: 5000, theme: "colored"});
        break;
    case 'warning':
        toast.warn(_message, { position: "top-right", autoClose: 5000, theme: "colored"});
        break;
    case 'warn':
        toast.warn(_message, { position: "top-right", autoClose: 5000, theme: "colored"});
        break;
    case 'danger':
        toast.danger(_message, { position: "top-right", autoClose: 5000, theme: "colored"});
        break;
    case 'error':
        toast.error(_message, { position: "top-right", autoClose: 5000, theme: "colored"});
        break;
    default:
        toast.info(_message, { position: "top-right", autoClose: 5000, theme: "colored"});
        break;
    }
}