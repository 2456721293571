import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect';
const UnderMaintenancePage = () => {
const [pageMounted, setPageMounted] = useState(false)

useEffect(() => {
    if(!pageMounted){
        document.body.style.backgroundColor = "var(--gg-background)";
        setPageMounted(true);
    }
})

return <>
<div className="row align-items-center justify-content-center vh-90 gg-bg sign-in-page mt-4">
    {/* <div className="row d-flex justify-content-center">
        <div className="col-10 text-center">
        {isMobile? <br />: null}
            <div className="alert alert-warning fs-3 w-bolder" role="alert">
            Please Note: We implemented a major upgrade of our system on 08 April 2023 - Please reset your password if you have not logged in since then. 
            <br /> {isMobile? <br />: null}
            We apologize for the inconvenience and assure you it will be worth it!
        </div>
        </div>
    </div> */}
    <div className={`col-11 col-sm-8 col-md-6 col-lg-5 col-xl-4 col-xxl-3 ${isMobile? '': ' py-6 p-0'}`}>
        <div className="sign-in-bkg tab-pane show fade">
            <div className={`border-0  px-md-6 row d-flex justify-content-center mb-4`}>
                <img src="assets/gg-assets/glogo-blue.png" className="img-fluid" style={{height: '150px', width: '170px'}}/>
            </div>
            <div className="row d-flex justify-content-center m-0 p-0">
                <div className="card border-0 gg-bg-white pb-0 mb-0 p-0 ">
                    <div className="card-body text-center pb-1">
                        <h1 className='mb-2 opacity-75'>Under Maintenance</h1>
                        <p className='opacity-60 fs-3'>Were making the site more awesome</p>
                    </div>
                    <hr className='m-0 pb-1'/>
                    <div className="card-footer text-center pt-0">
                        If you require any assistance 
                        <br /> please contact us on
                        <br />
                        <a href="mailto:hello@go-gas.co.za"><b className='fs-3'>hello@go-gas.co.za</b></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</>
}

export default UnderMaintenancePage
