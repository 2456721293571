import { doPrivatePostRequest } from "./requests"

export const getShipDayOrderByOrderNo = (_order_no) => {
    const payload = {
        'fn': 'getShipDayOrderByTypeKeyValue',
        'params': {
            'st': 'BY-ORDER-NO',
            'sk': _order_no,
            'sv': null
        }
    }
    return doPrivatePostRequest(payload)
}

export const addOrderToShipDay = (_order_id) => {
    const payload = {
        'fn': 'addOrderToShipDay',
        'params': {
            'order_id': _order_id
        }
    }
    return doPrivatePostRequest(payload)
}