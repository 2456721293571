import React, {useState} from 'react'
import { doGlobalSearch as doGlobalSearchApi } from '../../api_v2/private/global';
import OrderModal from '../modals/order-modal';
import {getOrderById as getOrderByIdApi} from '../../api_v2/private/orders'

const Search = () => {
const [searchText, setSearchText] = useState('')
const [loadedOrder, setLoadedOrder] = useState(null)
const [showOrderModal, setShowOrderModal] = useState(false)

function onSearchInputChange(_value){
    setSearchText(_value)
}

function onSearchClick(){
    doGlobalSearchApi(searchText).then((_resp) => {
        if(_resp.data.Result === 'OK'){
            const order = _resp.data.Record
            loadOrderById(order['doc_id'])
        }else{
            console.warn(_resp.data)
        }
    }).catch((_resp) => {
        console.log(_resp)
    })
}

function loadOrderById(_doc_id){
    getOrderByIdApi(_doc_id).then((_resp) => {
        if(_resp.data.Result === 'OK'){
            const order = _resp.data.Record
            setLoadedOrder(order)
            setShowOrderModal(true)
        }else{
            console.warn(_resp)
        }
    }).catch((_resp) => {
        console.error(_resp)
    })
}

function onOrderModalResp(_cmd, _value){
    if(_cmd === 'close'){
        setShowOrderModal(false)
    }
}

return(
<div>
<form className="d-none d-md-inline-block me-auto">
    <div className="input-group input-group-merge">
        <input onChange={(e) => onSearchInputChange(e.target.value)} value={searchText} type="text" className="form-control bg-light-green border-light-green w-250px" placeholder="Search..." aria-label="Search for any term"/>
        <span className="input-group-text bg-light-green border-light-green p-0">
            <button onClick={() => onSearchClick()} className="btn gg-bg rounded-2 w-30px h-30px p-0 mx-1" type="button" aria-label="Search button">
                <svg viewBox="0 0 24 24" height={16} width={16} xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.750 9.812 A9.063 9.063 0 1 0 18.876 9.812 A9.063 9.063 0 1 0 0.750 9.812 Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" transform="translate(-3.056 4.62) rotate(-23.025)"/>
                    <path d="M16.221 16.22L23.25 23.25" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                </svg>
            </button>
        </span>
    </div>
</form>
<OrderModal order={loadedOrder} show={showOrderModal} onHide={onOrderModalResp}/>
</div>
)};
export default Search