import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoMenuOutline } from "react-icons/io5";
import GitInfo from 'react-git-info/macro';
import localSessionApi from '../../utils/local_session';

const NavBar = () => {
  // const navigate = useNavigate();
  const gitInfo = GitInfo();
  const [pageMounted, setPageMounted] = useState(false);
  const [gitBranch, setGitBranch] = useState('');
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    if (!pageMounted) {
      setPageMounted(true);
      setGitBranch(gitInfo.branch);
      const userData = localSessionApi.getWithExpiry('GO-GAS-AUTH-USER');
      const userRole = userData?.role.toLowerCase()
      setUserRole(userRole || '');
    }
  }, [pageMounted, gitInfo.branch]);

  const adminMenuItems = [
    { to: "/backend-staff-dashboard", label: "Dashboard" },
    { to: "/backend-staff-products", label: "Products" },
    { to: "/backend-staff-orders", label: "Orders" },
    {
      label: "Quotes",
      subItems: [
        { to: "/quotes/create-quote", label: "Create Quote" },
        { to: "/quotes/reports", label: "Quotes Reports" }
      ]
    },
    {
      label: "Credits",
      subItems: [
        { to: "/backend-staff-credits", label: "Credits" },
        { to: "/backend/inventory/cylinder/returns/admin", label: "Driver Returns" }
      ]
    },
    {
      label: "Discounts",
      subItems: [
        { to: "/backend-staff-coupons", label: "Coupons" },
        { to: "/backend-staff-users-promo-codes", label: "Promo Codes" }
      ]
    },
    {
      label: "Inventory",
      subItems: [
        { to: "/backend/inventory/cylinders/admin", label: "Cylinders" },
        { to: "/backend/inventory/cylinder/returns/admin", label: "Return Cylinder" }
      ]
    },
    {
      label: "Management",
      subItems: [
        { to: "/backend-staff-affiliates", label: "Affiliates" },
        { to: "/backend-staff-users", label: "Users" },
        { to: "/backend-staff-users-reset-tokens", label: "Reset Tokens" },
        { to: "/backend-staff-reports", label: "Reports" },
        { to: "/reports/frank", label: "Franks Reports" },
        { to: "/backend-staff-system-config", label: "System Config" }
      ]
    }
  ];

  const clientMenuItems = [
    { to: "/my-dashboard", label: "Dashboard" },
    { to: "/my-orders", label: "Orders" },
    { to: "/my-credits", label: "Credits" },
    { to: "/my-promo-code", label: "Refer A Friend" },
    { to: "/my-details", label: "My Details" }
  ];

  const affiliateMenuItems = [
    { to: "/affiliate-dashboard", label: "Dashboard" },
    { to: "/affiliate-orders", label: "Referral Orders" }
  ];

  const driverMenuItems = [
    { to: "/backend-driver-dashboard", label: "Dashboard" },
    { to: "/backend-driver-cylinders", label: "Returns" },
    { to: "/backend/inventory/cylinder/returns", label: "Returns V2" },
    { to: "/backend-staff-user-profile", label: "User Details" },
    { to: "/backend/inventory/cylinders", label: "All Cylinders" }
  ];

  const salesMenuItems = [
    { to: "/my-dashboard", label: "Dashboard" },
    { to: "/my-details", label: "My Details" }
  ]

  const renderMenuItems = (items) => (
    items.map((item, index) => (
      item.subItems ? (
        <li className="nav-item dropdown" key={index}>
          <Link to={`#${item.label.replace(" ", "")}Collapse`} className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="pagesCollapse">
            <span>{item.label}</span>
          </Link>
          <div className="collapse" id={`${item.label.replace(" ", "")}Collapse`}>
            <ul className="nav flex-column">
              {item.subItems.map((subItem, subIndex) => (
                <li className="nav-item" key={subIndex}>
                  <Link to={subItem.to} className="nav-link">{subItem.label}</Link>
                </li>
              ))}
            </ul>
          </div>
        </li>
      ) : (
        <li className="nav-item" key={index}>
          <Link to={item.to} className="nav-link">{item.label}</Link>
        </li>
      )
    ))
  );

  return (
    <nav id="mainNavbar" className="navbar navbar-vertical navbar-expand-lg scrollbar">
      <div className="container-fluid">
        <a className="navbar-brand" href="https://go-gas.co.za/">
          <img src="../../assets/gg-assets/glogo.png" className="navbar-brand-img logo-light logo-small" alt="logo" width={19} height={25} />
          <img src="../../assets/gg-assets/glogo.png" className="navbar-brand-img logo-light logo-large" alt="logo" width={125} height={25} />
          <img src="../../assets/gg-assets/glogo.png" className="navbar-brand-img logo-dark logo-small" alt="logo" width={19} height={25} />
          <img src="../../assets/gg-assets/glogo.png" className="navbar-brand-img logo-dark logo-large" alt="logo" width={200} height={75} />
        </a>
        {['dev', 'uat'].includes(gitBranch) && (
          <span className="text-white"><b>Environment: <span className="text-warning">{gitBranch.toUpperCase()}</span></b></span>
        )}
        <a href="/" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#sidenavCollapse" aria-controls="sidenavCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <IoMenuOutline className="hamburger-icon" />
        </a>
        <div className="collapse navbar-collapse" id="sidenavCollapse">
          <ul className="navbar-nav mb-lg-7">
            {['sales'].includes(userRole) && renderMenuItems(salesMenuItems)}
            {['customer', 'client'].includes(userRole) && renderMenuItems(clientMenuItems)}
            {['driver'].includes(userRole) && renderMenuItems(driverMenuItems)}
            {['affiliate'].includes(userRole) && renderMenuItems(affiliateMenuItems)}
            {['admin', 'backend-staff'].includes(userRole) && renderMenuItems(adminMenuItems)}
          </ul>
        </div>
        <a role="button" className="btn btn-secondary text-gg-blue" href="/">Order Gas</a>
      </div>
    </nav>
  );
};

export default NavBar;
