import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'
import AdminRoutes from "./AdminRoutes"
import CustomerRoutes from "./CustomerRoutes"
import DriverRoutes from "./DriverRoutes"
import AffiliatesRoutes from "./AffiliatesRoutes"
import AdsSalesRoutes from './AdsSalesRoutes';
import TokenRoutes from './TokenRoutes';
import PageLoaderComp from '../components/skeletonLoaders/pageLoader';
import localSessionApi, {token_key, user_data_key} from "./local_session"
// PUBLIC ROUTES
const SignInPage = React.lazy(() => import('../pages/authPages/SignIn'))
const SignUpPage = React.lazy(() => import('../pages/authPages/SignUp'))

const TwoStepAuthPage = React.lazy(() => import('../pages/authPages/TwoStepAuth'))
const GoGasWizard = React.lazy(() => import('../pages/publicPages/GoGasWizard/ggWizard'))
const ForgotPwPage = React.lazy(() => import('../pages/authPages/reset/ForgotPw'));
const ResetPwPage = React.lazy(() => import( '../pages/authPages/reset/ResetPw'));
const SuccessPaymentReturn = React.lazy(() => import( '../pages/publicPages/GoGasWizard/payment-return/success'));
const CancelPaymentReturn = React.lazy(() => import( '../pages/publicPages/GoGasWizard/payment-return/cancel'));

// PROTECTED ROUTED
const ProductsPage = React.lazy(() => import('../pages/backendStaffPages/products-page'));
const UsersPage = React.lazy(() => import('../pages/backendStaffPages/users-page'))
const DashboardPage = React.lazy(() => import('../pages/backendStaffPages/Dashboard'))
const DriverDashboardPage = React.lazy(() => import('../pages/backendStaffPages/DriverDashboard'))
const SettingsPage = React.lazy(() => import('../pages/backendStaffPages/genSettings'))
const UserDetailsPage = React.lazy(() => import('../pages/userProfilePages/UserDetails'))
const SharePointPage = React.lazy(() => import('../pages/SharePoint'))
const CalendarPage = React.lazy(() => import('../pages/calendarPages/calendar-page'))
const OrdersPage = React.lazy(() => import('../pages/backendStaffPages/orders-page'))
const ErrorsPage = React.lazy(() => import('../pages/authPages/Error'));
const CouponPage = React.lazy(() => import('../pages/backendStaffPages/coupons-page'));
const CylinderReturnsPage = React.lazy(() => import('../pages/backendStaffPages/cylinder-returns'));
const CylinderWeightsPage = React.lazy(() => import('../pages/backendStaffPages/cylinder-weights'));
const DriverCylindersPage = React.lazy(() => import('../pages/backendStaffPages/driver-cylinder'));
const CreditsPage = React.lazy(() => import('../pages/backendStaffPages/credits-page'));
const DeliveriesPage = React.lazy(() => import('../pages/backendStaffPages/Delivery'));
const LogsPage = React.lazy(() => import('../pages/Logs'));
const ReportssPage = React.lazy(() => import('../pages/backendStaffPages/Reports'));
const IntegrationsPage = React.lazy(() => import('../pages/backendStaffPages/integrations'));
const ResetTokensPage = React.lazy(() => import('../pages/backendStaffPages/PwResetTokensPage'));
const PromoCodesPage = React.lazy(() => import('../pages/backendStaffPages/promo_codes'))
const MyPromoCodePage = React.lazy(() => import('../pages/userProfilePages/user-promo-code'))
const AdminSystemLogs = React.lazy(() => import('../pages/backendStaffPages/systemLogs'))
const AdminSystemConfig = React.lazy(() => import('../pages/backendStaffPages/systemConfig'))
const AffiliatesPage = React.lazy(() => import('../pages/backendStaffPages/affiliates-page'))
const InventoryCylinderPage = React.lazy(() => import('../pages/backendStaffPages/inventory/cylinders-page'))
const CylinderReturnsPageV2 = React.lazy(() => import('../pages/backendStaffPages/cylinder-returns-v2'))
const FranksReportsPage = React.lazy(() => import('../pages/backendStaffPages/reports/franks-reports'))

// AFFILIATE ROUTES
const AffiliateOrdersPage = React.lazy(() => import('../pages/affiliatePages/ordersPage'))
const AffiliateDashboardPage = React.lazy(() => import('../pages/affiliatePages/dashboardPage'))
const AffiliateUserDetailsPage = React.lazy(() => import('../pages/affiliatePages/UserDetails'))

//CLIENT ROUTED
const CustomerDashboard = React.lazy(() => import('../pages/dashboards/CustomerDash'))
const UserProfilePage = React.lazy(() => import('../pages/userProfilePages/UserProfile'))
const UserCouponPage = React.lazy(() => import('../pages/userProfilePages/user-coupons'))
const UserCreditPage = React.lazy(() => import('../pages/userProfilePages/user-credits'))
const UserOrdersPage = React.lazy(() => import('../pages/userProfilePages/user-orders'))

// QUOTES PAGES
const CreateQuotePage = React.lazy(() => import('../pages/quotes/ordering-page'))
const QuotesReportPage = React.lazy(() => import('../pages/quotes/quotes-reports'))

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <div>
                        <h1 style={{ textAlign: 'center' }}>An error occurred</h1>
                        <p style={{ textAlign: 'center' }}>this is a test error</p>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

const LoadComponent = ({ component: Component }) => (
    <Suspense fallback={<PageLoaderComp/>}>
        <ErrorBoundary>
            <Component />
        </ErrorBoundary>
    </Suspense>
);

const shared_comps = {
    '/my-details': {
        'customer': UserDetailsPage,
        'sales': UserDetailsPage
    },
    '/my-dashboard': {
        'customer': CustomerDashboard,
        'sales': CreateQuotePage
    }
}

function useRouteChange() {
    const location = useLocation();
    const path = location['pathname'];
    // console.log('current path: ', path);

    function manageChatBot(){
        try {
            let ele = document.getElementById('btnOpenChatApp');
            if (ele) {
                if(['/'].includes(path)){
                    ele.style.display = 'block';
                } else {
                    ele.style.display = 'none';
                }
            }
        } catch (error) {}
    }

    function manageCallMeBackBtn(){
        try {
            let ele = document.getElementById('requestACallBtn');
            if (ele) {
                if(['/'].includes(path)){
                    ele.style.display = 'block';
                } else {
                    ele.style.display = 'none';
                }
            }
        } catch (error) {}
    }

    useEffect(() => {
        setTimeout(() => {manageChatBot()}, 500)
        setTimeout(() => {manageCallMeBackBtn()}, 500)

    }, [location]);
}

const HandleSharedComp = ({ path: Path }) => {
    const user = localSessionApi.getWithExpiry(user_data_key)
    const user_role = user['role'].toLowerCase()
    const comp = shared_comps[Path][user_role]
    return <LoadComponent component={comp} />
}

const AllRoutes = () => {
useRouteChange();

return <>
    <Routes>
        {/* SHARED ROUTES between users */}
        <Route element={<TokenRoutes />}>
            <Route path='/my-dashboard' element={<HandleSharedComp path={'/my-dashboard'}/>} exact />
            <Route path='/my-details' element={<HandleSharedComp path={'/my-details'}/>} exact />
        </Route>
        <Route element={<CustomerRoutes />}>
            {/* <Route path='/my-dashboard' exact element={<LoadComponent component={CustomerDashboard} />} /> */}
            <Route path='/my-orders' exact element={<LoadComponent component={UserOrdersPage}/>} />
            <Route path='/my-credits' exact element={<LoadComponent component={UserCreditPage}/>} />
            <Route path='/my-coupons' exact element={<LoadComponent component={UserCouponPage}/>} />
            {/* <Route path='/my-details' exact element={<LoadComponent component={UserDetailsPage}/>} /> */}
            <Route path='/my-promo-code' exact element={<LoadComponent component={MyPromoCodePage}/>} />
        </Route>
        <Route element={<AffiliatesRoutes />}>
            <Route path='/affiliate-dashboard' exact element={<LoadComponent component={AffiliateDashboardPage} />} />
            <Route path='/affiliate-orders' exact element={<LoadComponent component={AffiliateOrdersPage}/>} />
            <Route path='/affiliate-my-details' exact element={<LoadComponent component={AffiliateUserDetailsPage}/>} />
        </Route>
        <Route element={<DriverRoutes />}>
            <Route path='/backend-driver-dashboard' exact element={<LoadComponent component={DriverDashboardPage} />} />  
            <Route path='/backend-driver-cylinders' exact element={<LoadComponent component={DriverCylindersPage} />} />
            <Route path='/backend/inventory/cylinder/returns' exact element={<LoadComponent component={CylinderReturnsPageV2} />} />
            <Route path='/backend-staff-user-profile' exact element={<LoadComponent component={UserProfilePage} />} />
            <Route path='/backend/inventory/cylinders' exact element={<LoadComponent component={InventoryCylinderPage}/>}/>
        </Route>
        <Route element={<AdminRoutes />}> 
            <Route path='/backend-staff-dashboard' exact element={<LoadComponent component={DashboardPage}/>} />
            <Route path='/backend-staff-products' exact element={<LoadComponent component={ProductsPage} />} />
            <Route path='/backend-staff-users' exact element={<LoadComponent component={UsersPage} />} />
            <Route path='/backend-staff-coupons' exact element={<LoadComponent component={CouponPage} />} />
            <Route path='/backend-staff-credits' exact element={<LoadComponent component={CreditsPage} />} />
            <Route path='/backend-cylinder-returns' exact element={<LoadComponent component={CylinderReturnsPage} />} />
            <Route path='/backend-cylinder-weights' exact element={<LoadComponent component={CylinderWeightsPage} />} />
            <Route path='/backend-staff-delivery' exact element={<LoadComponent component={DeliveriesPage} />} />
            <Route path='/backend-staff-general-settings' exact element={<LoadComponent component={SettingsPage} />} />
            <Route path='/backend-staff-integrations' exact element={<LoadComponent component={IntegrationsPage}/>} />
            <Route path='/backend-staff-user-profile' exact element={<LoadComponent component={UserProfilePage} />} />
            <Route path='/backend-staff-share-point' exact element={<LoadComponent component={SharePointPage} />} />
            <Route path='/backend-staff-calendar' exact element={<LoadComponent component={CalendarPage} />} />
            <Route path='/backend/inventory/cylinder/returns/admin' exact element={<LoadComponent component={CylinderReturnsPageV2} />} />
            <Route path='/backend-staff-orders' exact element={<LoadComponent component={OrdersPage} />} />
            <Route path='/backend-staff-orders/:cmd/:value' exact element={<LoadComponent component={OrdersPage} />} />
            <Route path='/backend-staff-logs' exact element={<LoadComponent component={LogsPage} />} />
            <Route path='/backend-staff-reports' exact element={<LoadComponent component={ReportssPage} />} />
            <Route path='/backend-staff-users-reset-tokens' exact element={<LoadComponent component={ResetTokensPage}/>}/>
            <Route path='/backend-staff-users-promo-codes' exact element={<LoadComponent component={PromoCodesPage}/>}/>
            <Route path='/backend-staff-my-details' exact element={<LoadComponent component={UserDetailsPage}/>} />
            <Route path='/backend-staff-system-config' element={<LoadComponent component={AdminSystemConfig}/>}/>
            <Route path='/backend-staff-affiliates' exact element={<LoadComponent component={AffiliatesPage}/>}/>
            <Route path='/backend/inventory/cylinders/admin' exact element={<LoadComponent component={InventoryCylinderPage}/>}/>
            <Route path='/quotes/create-quote' exact element={<LoadComponent component={CreateQuotePage} />} />
            <Route path='/quotes/reports' exact element={<LoadComponent component={QuotesReportPage} />} />
            <Route path='/reports/frank' exact element={<LoadComponent component={FranksReportsPage} />} />
        </Route>
        <Route element={<LoadComponent component={GoGasWizard} />} exact path='/' />
        <Route element={<LoadComponent component={GoGasWizard} />} exact path='/:discounttype/:discountvalue' />
        <Route element={<LoadComponent component={GoGasWizard} />} exact path='/:cmd/:value/:qualifier' />
        <Route element={<LoadComponent component={SignInPage}/>} exact path='/login'/>
        <Route element={<LoadComponent component={SignUpPage}/>} exact path='/register'/>
        <Route element={<LoadComponent component={ForgotPwPage}/>} exact path='/forgot-pw'/>
        <Route element={<LoadComponent component={ResetPwPage}/>} exact path='/reset-pw/:resetid'/>
        <Route element={<LoadComponent component={ResetPwPage}/>} exact path='/reset.pw/:resetid'/>
        <Route element={<LoadComponent component={TwoStepAuthPage} />} exact path='/two-fac-auth'/>
        <Route element={<LoadComponent component={SuccessPaymentReturn} />} exact path='/order/success/:orderno' />
        <Route element={<LoadComponent component={CancelPaymentReturn} />} exact path='/order/cancel/:orderno' />
        <Route element={<LoadComponent component={ErrorsPage}/>} path="*"/>
    </Routes>
</>
}

export default AllRoutes