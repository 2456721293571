import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { IoClose } from 'react-icons/io5';
import { addOrderToShipDay as addOrderToShipDayApi } from '../../api_v2/private/shipday';
import { makeToast } from '../../functions/toats';

const ConfirmAddOrderToShipday = ({show, params, onResp}) => {
const btnAddToSdBaseText = 'Add'

const [btnAddToSdText, setBtnAddToSdText] = useState(btnAddToSdBaseText)

useEffect(() => {
    if (show) {
        document.body.classList.add('body--modal-open');
    } else {
        document.body.classList.remove('body--modal-open');
    }
}, [show]);

function addOrderToShipDay(){
    setBtnAddToSdText('Adding...')
    addOrderToShipDayApi(params['order_id']).then((_resp) => {
        setBtnAddToSdText(btnAddToSdBaseText)
        if(_resp.data.Result === 'SUCCESS'){
            makeToast(_resp.data.Result.toLowerCase(), _resp.data.Message)
            onResp('close', null)
        }else{
            makeToast(_resp.data.Result.toLowerCase(), _resp.data.Message)
        }
    }).catch((_resp) => {
        setBtnAddToSdText(btnAddToSdBaseText)
        console.error(_resp)
    })
}
    
return <>
    <Modal backdrop={true} show={show} size="md" dialogClassName='shadow modal-shadow' centered>
        <Modal.Header className='p-4 border border-dark'>
            <Modal.Title id="contained-modal-title-vcenter">
                <label className='fs-3 text-gg-blue'>Add order <b>{params.order_no}</b> to Shipday</label>
            </Modal.Title>
            <div>
                <IoClose onClick={() => onResp('close', null)} className='fs-1 mp text-dark'/>
            </div>
        </Modal.Header>
        <Modal.Body className='p-4 border border-dark'>
            <div className='d-flex justify-content-center'> 
                <p className='fs-3'>
                    This order is not in shipday.
                    <br />
                    Do you want to add it?
                </p>
            </div>
        </Modal.Body>
        <Modal.Footer className='m-0 p-0 border border-dark'>
            <button onClick={() => onResp('close', '')} type="button" className="btn btn-outline-info">Cancel</button>
            <button onClick={() => addOrderToShipDay()} disabled={btnAddToSdText !== btnAddToSdBaseText} type="button" className="btn btn-primary">{btnAddToSdText}</button>
        </Modal.Footer>
    </Modal>
</>
}

export default ConfirmAddOrderToShipday