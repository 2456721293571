import axios from "axios";
const PROXY_ENDPOINT = process.env.REACT_APP_PROXY_V2_ENDPOINT

export function doPublicPostRequest(_payload){
    const config = {
        method: 'post',
        url: `${PROXY_ENDPOINT}/public`,
        timeout: 40000,
        headers: {'Content-Type': 'application/json'},
        data : _payload
    };
    return axios(config)
}
