import { Outlet } from "react-router-dom";
import Header from "../components/baseComponents/Header"
import NavBar from "../components/baseComponents/NavBar"

const Layout = () => {
return <>
<NavBar></NavBar>
<main className="main-layout">
    <Header/>
    <div>
        <Outlet/>
    </div>
</main>
</>
}

export default Layout