import React from "react";
import { Navigate } from "react-router-dom";
import Layout from "./Layout";
import localSessionApi, {token_key, user_data_key} from "./local_session";

const TokenRoutes = () => {
    let hasToken = false
    const jwt = localSessionApi.getWithExpiry(token_key)

    if(jwt !== null){
        hasToken = true;
    }
    
    let auth = {'hasToken': hasToken}
    return(
        auth.hasToken ? <Layout/> : <Navigate to='login' />
    )
}

export default TokenRoutes