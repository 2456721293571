import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import { IoClose, IoReloadOutline, IoDocumentTextSharp, IoMapSharp, IoSend } from "react-icons/io5";
import { MdOutlinePayment } from "react-icons/md";
import localSessionApi, { user_data_key } from '../../utils/local_session'
import { getShipDayOrderByOrderNo as getShipDayOrderByOrderNoApi } from '../../api_v2/private/shipday';
import { makeToast } from '../../functions/toats';
import ConfirmAddOrderToShipday from '../dialogModals/confirmAddOrderToShipday';
import { runOrderPaymentNotifications as runOrderPaymentNotificationsApi } from '../../api_v2/private/orders';
import { syncOrderWithShipDayOrderNotes as syncOrderWithShipDayOrderNotesApi } from '../../api_v2/private/orders';
import { toast } from 'react-toastify';

const OrderModal = ({order, show, onHide}) => {
const APP_ENV = process.env.REACT_APP_ENV
const REACT_APP_PROXY_V2_ENDPOINT = process.env.REACT_APP_PROXY_V2_ENDPOINT
const logged_in_user = localSessionApi.getWithExpiry(user_data_key)
const USER_ROLE = logged_in_user['role']

const baseReSyncSdNotesText = 'ReSync ShipDay Notes'
const [compMounted, setCompMounted] = useState(false)
const [orderDetails, setOrderDetails] = useState(null)
const [sentBtnDisabled, setSendBtnDisabled] = useState(false)
const [btnResyncSdText, setBtnResyncSdText] = useState(baseReSyncSdNotesText)

const [showConfirmAddOrderToShipday, setShowConfirmAddOrderToShipday] = useState(false)
const [confirmAddOrderToShipdayParams, setConfirmAddOrderToShipdayParams] = useState({
    'order_id': '',
    'order_no': ''
})

useEffect(() => {
    if(!compMounted){
        setOrderDetails(order)
        setCompMounted(true)
    }
}, [compMounted])

const isNewOrder = (_product) => {
    if(_product['_id']){return false}
    return true
}

function onValueChange(_key, _value){
    order[_key] = _value
}

function onSelectValueChange(_key, _value, _e){
    _e.stopPropagation();
    order[_key] = _value
}

function onUpdateClick(){
    onHide('update', order)
}

function onCreateClick(){
    onHide('create', order)
}

function onRemoveClick(){
    onHide('delete', order)
}

function getCustomerDetails(){
    try {
        return order.meta_data.customer_details.contact_email
    } catch (error) {
        return 'None'
    }
}

function getClientFullname(){
    try {
        return order.meta_data.customer_details.fullname
    } catch (error) {
        return 'None'
    }
}

function getStatusClass(){
    let text = 'form-control'
    if(order['status'] === 'pending'){
        text = text + ' border border-secondary border-3'
    }else if(order['status'] === 'processing'){
        text = text + ' border border-warning border-3'
    }else if(order['status'] === 'completed'){
        text = text + ' border border-success border-3'
    }else if(order['status'] === 'cancelled'){
        text = text + ' border border-danger border-3'
    }
    return text
}

function onInvoiceClick(_order){
    const order_id = _order['_id']
    let origin = ''
    
    if(['DEV'].includes(APP_ENV)){
        origin = REACT_APP_PROXY_V2_ENDPOINT
    }else{
        origin = `${window.location.origin}/service`
    }
    const link = `${origin}/pdf-invoice/${order_id}`
    window.open(link, '_blank');
}

function checkIfOrderInShipDay(_order){
    const order_no = _order['orderNumber']
    getShipDayOrderByOrderNoApi(order_no).then((_resp) => {
        if(_resp.data.Result === 'OK'){
            if(_resp.data.Record !== null){
                makeToast('success', `Order [${order_no}] in ship-day`)
            }else{
                makeToast('info', `Order [${order_no}] not in ship-day`)
                setShowConfirmAddOrderToShipday(true)
                setConfirmAddOrderToShipdayParams({
                    'order_id': _order['_id'],
                    'order_no': _order['orderNumber']
                })
            }
        }else{
            makeToast(_resp.data.Result.toLowerCase(), _resp.data.Message)
        }
        console.log(_resp)
    }).catch((_resp) => {
        console.error(_resp)
    })
}

function onConfirmAddOrderToShipdayResp(_cmd, _value){
    if(_cmd === 'close'){
        setShowConfirmAddOrderToShipday(false)
    }
}

function onPaymentLinkClick(){
    makeToast('info', 'Under Development')
}

function orderPaymentNotificationsClick(){
    const order_status = order['status']
    if(!['processing', 'completed', 'complete'].includes(order_status)){
        makeToast('warning', 'Wrong order status')
        return
    }
    setSendBtnDisabled(true)
    runOrderPaymentNotificationsApi(order['_id']).then((_resp) => {
        if(_resp.data.Result === 'OK'){
            makeToast('success', 'Notification Scheduled')
        }else{
            makeToast('warning', _resp.data.Message)
        }
    }).catch((_resp) => {
        makeToast('error', 'Failed to send out Order Payment Notifications')
    }).finally(() => {
        setSendBtnDisabled(false)
    })
}

function onReSyncSdNotesClick(){
    setBtnResyncSdText('Syncing...')
    syncOrderWithShipDayOrderNotesApi(order['_id']).then((_resp) => {
        if(_resp.data.Result === 'SUCCESS'){
            onHide('reload', null)
        }else{
            toast.warning(_resp.data.Message)
        }
    }).catch((_resp) => {
        toast.error('Failed to SyncUp with ShipDay')
    }).finally(() => {
        setBtnResyncSdText(baseReSyncSdNotesText)
    })
}

return (
<>
    {show?
        <Modal show={show} backdrop={true} size="lg">
            <Modal.Header className='pb-1 pt-2'>
                <Modal.Title id="contained-modal-title-vcenter">
                    Order Details - <b className='text-gg-blue'>#{order.orderNumber}</b>
                    {order['createdBy'] !== 'self_order'?
                        <>
                            <br />
                            <small>Created By: <span className='opacity-50 text-gg-blue'>{order.createdBy}</span></small>
                        </>
                    : null}
                    <br />
                    <small>Email: <span className='opacity-50 text-gg-blue'>{getCustomerDetails()}</span></small><br />
                    <small>Client: <span className='opacity-50 text-gg-blue'>{getClientFullname()}</span></small>
                </Modal.Title>
                <div>
                    <IoSend onClick={orderPaymentNotificationsClick} disabled={sentBtnDisabled} title='Resend Post Payment Notifications' className='fs-3 mp text-success me-4'/>
                    <MdOutlinePayment onClick={onPaymentLinkClick} title='Payment Link' className='fs-3 mp text-danger me-4'/>
                    {['admin'].includes(USER_ROLE.toLowerCase())?
                        <IoMapSharp onClick={() => checkIfOrderInShipDay(order)} title='Check If On ShipDay' className='fs-3 mp text-success me-4'/>
                    : null}
                    <IoReloadOutline onClick={() => onHide('reload', null)} className='fs-3 mp text-info me-4'/>
                    <IoDocumentTextSharp onClick={() => onInvoiceClick(order)} title='Open Invoice' className='fs-3 mp text-secondary me-4'/>
                    <IoClose onClick={() => onHide('close', null)} className='fs-1 mp text-danger'/>
                </div>
            </Modal.Header>
            <Modal.Body className='ribbon-parent border pt-1 pb-1'>
                {['UAT', 'DEV'].includes(order.ENV)?
                    <h5 className="ribbon ribbon-order">TEST ORDER</h5>
                :null}
                <div className="row mb-2">
                    {/* <OrderStatusEle/> */}
                    {['admin'].includes(USER_ROLE.toLowerCase())?
                        <div className="col-md-6 mt-2">
                            <label className="form-label">Status</label>
                            <select id='order_status_select' onChange={(e) => onSelectValueChange('status', e.target.value, e)} value={order.status} className={getStatusClass()}>
                                <option value="pending">Pending</option>
                                <option value="processing">Processing</option>
                                {/* <option value="failed">Failed</option> */}
                                {/* <option value="On-Hold">On Hold</option> */}
                                <option value="completed">Completed</option>
                                <option value="cancelled">Cancelled</option>
                            </select>
                        </div>
                    :
                        <div className="col-md-6 mt-2">
                            <label className="form-label">Status</label>
                            <select defaultValue={order.status} className={getStatusClass()} disabled>
                                <option value="pending">Pending</option>
                                <option value="processing">Processing</option>
                                {/* <option value="failed">Failed</option> */}
                                {/* <option value="On-Hold">On Hold</option> */}
                                <option value="completed">Completed</option>
                                <option value="cancelled">Cancelled</option>
                            </select>
                        </div>
                    }
                    <div className="col-md-6 mt-2">
                        <label className="form-label">Created Date</label>
                        <input type="text" className="form-control" onChange={(e) => onValueChange('createdOn', e.target.value)} defaultValue={order.createdOn.substring(0, 16)} disabled/>
                    </div>
                </div>
                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item">
                        <a href={`#info_${order['_id']}`} data-bs-toggle="tab" aria-expanded="false" className="nav-link active">
                            <i className="mdi mdi-home-variant d-md-none d-block"></i>
                            <span className="d-none d-md-block">Info</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href={`#address_${order['_id']}`} data-bs-toggle="tab" aria-expanded="true" className="nav-link">
                            <i className="mdi mdi-account-circle d-md-none d-block"></i>
                            <span className="d-none d-md-block">Address</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href={`#affiliate_${order['_id']}`} data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                            <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                            <span className="d-none d-md-block">Affiliate</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href={`#payfast_${order['_id']}`} data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                            <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                            <span className="d-none d-md-block">Payfast</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href={`#credit_${order['_id']}`} data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                            <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                            <span className="d-none d-md-block">Credit</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href={`#xero_${order['_id']}`} data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                            <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                            <span className="d-none d-md-block">Xero</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href={`#discounts_${order['_id']}`} data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                            <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                            <span className="d-none d-md-block">Discounts</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href={`#shipday_${order['_id']}`} data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                            <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                            <span className="d-none d-md-block">ShipDay</span>
                        </a>
                    </li>
                </ul>

                <div className="tab-content">
                    <div className="tab-pane show active" id={`info_${order['_id']}`}>
                        <div className="row mb-2">
                            <div className="col-md-4">
                                <label className='form-label'>Deposit Total</label>
                                <input defaultValue={order.meta_data.depositTotal.toFixed(2)} type="text" className='form-control' disabled />
                            </div>
                            <div className="col-md-4">
                                <label className='form-label'>Sub Total</label>
                                <input defaultValue={order.meta_data.subTotal.toFixed(2)} type="text" className='form-control' disabled />
                            </div>
                            <div className="col-md-4">
                                <label className='form-label'>Total Price</label>
                                <input defaultValue={order.meta_data.totalPrice.toFixed(2)} type="text" className='form-control' disabled />
                            </div>
                            <div className="col-md-4">
                                <label className='form-label'>VAT</label>
                                <input defaultValue={order.vat.toFixed(2)} type="text" className='form-control' disabled />
                            </div>
                        </div>
                        <hr className='m-4 opacity-50'/>
                        <div className="row mb-2">
                            <div className="col-md-4">
                                <label className='form-label'>Connect Appliance</label>
                                <select defaultValue={order.meta_data.connectAppliance} className='form-control' disabled>
                                    <option value="false">NO</option>
                                    <option value="true">YES</option>
                                </select>
                            </div>
                            <div className="col-md-4">
                                <label className='form-label'>Amount</label>
                                <input defaultValue={order.meta_data.connectApplianceCost.toFixed(2)} type="text" className='form-control' disabled />
                            </div>
                        </div>
                        {order.hasOwnProperty('delivery_fee')?
                            <>
                            <hr className='m-4 opacity-50'/>
                            <div className="row mb-2">
                                <div className="col-md-4">
                                    <label className='form-label'>Delivery Fee</label>
                                    <select defaultValue={order.delivery_fee.has_fee} className='form-control' disabled>
                                        <option value="false">NO</option>
                                        <option value="true">YES</option>
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <label className='form-label'>Amount</label>
                                    <input defaultValue={order.delivery_fee.fee.toFixed(2)} type="text" className='form-control' disabled />
                                </div>
                            </div>
                            </>
                        : null}
                        <hr className='m-4 opacity-50'/>
                        <div className="row mt-6">
                            <h5 className='mb-0'>Order Items</h5>
                            <div className="table-responsive">
                                <table className="table order-items-table align-middle table-edge table-hover table-nowrap mb-0">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Product</th>
                                            <th>Price</th>
                                            <th>Quantity</th>
                                            <th>Total Price</th>
                                        </tr> 
                                    </thead>
                                    <tbody className="list">
                                        {order.items?.map((item, index) =>
                                            <tr key={index}>
                                                <td>{item.name}</td>
                                                <td>R{item.price.toFixed(2)}</td>
                                                <td>{item.quantity}</td>
                                                <td>R{(item.price*item.quantity).toFixed(2)}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <h5 className='mb-0'>TradeIn's</h5>
                            <div className="table-responsive">
                                <table className="table order-items-table align-middle table-edge table-hover table-nowrap mb-0">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Product</th>
                                            <th>Price</th>
                                            <th>Quantity</th>
                                            <th>Total Price</th>
                                        </tr> 
                                    </thead>
                                    <tbody className="list">
                                        {order['meta_data']['tradeInProducts']?.map((item, index) =>
                                            <tr key={index}>
                                                <td>{item.name}</td>
                                                <td>{item.text}</td>
                                                <td>{item.quantity}</td>
                                                <td>R{(item.price*item.quantity).toFixed(2)}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id={`address_${order['_id']}`}>
                        <div className="row mb-2">
                            <div className="col-12">
                                {order.hasOwnProperty('address')?
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title mb-4">Location: {order['address']['addressName']}</h4>
                                            <div className="row mb-2">
                                                <div className="col-4 font-weight-bold">Apartment Number:</div>
                                                <div className="col-8">{order['address']['apartmentNumber']}</div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-4 font-weight-bold">Building Name:</div>
                                                <div className="col-8">{order['address']['buildingName']}</div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-4 font-weight-bold">Address:</div>
                                                <div className="col-8">{order['address']['address']}</div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-4 font-weight-bold">Postal Code:</div>
                                                <div className="col-8">{order['address']['postalCode']}</div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-4 font-weight-bold">Country:</div>
                                                <div className="col-8">{order['address']['country']}</div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-4 font-weight-bold">Province:</div>
                                                <div className="col-8">{order['address']['province']}</div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-4 font-weight-bold">City:</div>
                                                <div className="col-8">{order['address']['city']}</div>
                                            </div>
                                        </div>
                                    </div>
                                : null}
                            </div>
                            {order.addresses?.map((address, index) => {
                                if(address.type === 'shipping'){
                                    let addrStr = address.address + ',' + address.city + ',' + address.province + ',' + address.postalCode
                                    return(
                                        <div key={index} className="col-md-6 mt-4">
                                            <label className="form-label">Customer Shipping Address</label>
                                            <input type="text" className="form-control" disabled onChange={(e) => onValueChange('address', e.target.value)} defaultValue={addrStr}/>
                                        </div>
                                        );
                                }
                            })}
                            {order.addresses?.map((address, index) => {
                                if(address.type === 'billing'){
                                    let addrStr = address.address + ',' + address.city + ',' + address.province + ',' + address.postalCode
                                    return(
                                        <div key={index} className="col-md-6 mt-2">
                                            <label className="form-label">Customer Billing Address</label>
                                            <input type="text" className="form-control" disabled onChange={(e) => onValueChange('address', e.target.value)} defaultValue={addrStr}/>
                                        </div>
                                    );
                            }})
                            }
                        </div>        
                    </div>
                    <div className="tab-pane" id={`affiliate_${order['_id']}`}>
                        {order.hasOwnProperty('affiliate')?
                            <>
                                <div className="row mb-2">
                                    <div className="col-md-4">
                                        <label className='form-label'>Affiliate</label>
                                        <select defaultValue={order.affiliate.used} className='form-control' disabled>
                                            <option value="false">NO</option>
                                            <option value="true">YES</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label className='form-label'>Code</label>
                                        <input defaultValue={order.affiliate.code} type="text" className='form-control' disabled />
                                    </div>
                                    <div className="col-md-4">
                                        <label className='form-label'>Total</label>
                                        <input defaultValue={order.affiliate.total} type="text" className='form-control' disabled />
                                    </div>
                                </div>
                            </>
                        : null}
                    </div>
                    <div className="tab-pane" id={`payfast_${order['_id']}`}>
                        <div className="card">
                            <div className="row mb-2">
                                <div className="col-md-4">
                                    <label className='form-label'>Payment Status</label>
                                    <input defaultValue={order.meta_data.payfast_resp.payment_status} type="text" className='form-control' disabled />
                                </div>
                                <div className="col-md-4">
                                    <label className='form-label'>Payment Amount</label>
                                    <input defaultValue={order.meta_data.payfast_resp.amount_gross} type="text" className='form-control' disabled />
                                </div>
                                <div className="col-md-4">
                                    <label className='form-label'>Payment Reply Date</label>
                                    <input defaultValue={order.meta_data.payfast_resp.date} type="text" className='form-control' disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id={`credit_${order['_id']}`}>
                        <div className="row mb-2">
                            <div className="col-md-4">
                                <label className='form-label'>Credit</label>
                                <select defaultValue={order.credit.used} className='form-control' disabled>
                                    <option value="false">NO</option>
                                    <option value="true">YES</option>
                                </select>
                            </div>
                            <div className="col-md-4">
                                <label className='form-label'>Amount</label>
                                <input defaultValue={order.credit.total.toFixed(2)} type="text" className='form-control' disabled />
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id={`xero_${order['_id']}`}>
                        {order['invoiced_on_xero']?
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-10">
                                    <div className="table-responsive">
                                        <table className='table table-sm table-hover'>
                                            <thead>
                                                <tr>
                                                    <th>Invoiced</th>
                                                    <th>Invoiced On</th>
                                                    <th>Invoice No</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{order['invoiced_on_xero']['invoiced']? 'YES' : 'NO'}</td>
                                                    <td>{order['invoiced_on_xero']['invoiced_on'].substring(0, 16)}</td>
                                                    <td>{order['invoiced_on_xero']['invoice_no']}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        : null}
                    </div>
                    <div className='tab-pane' id={`discounts_${order['_id']}`}>
                        {order.hasOwnProperty('promo_code')?
                            <>
                                <div className="row mb-2">
                                    <div className="col-md-4">
                                        <label className='form-label'>Promo Code</label>
                                        <select defaultValue={order.promo_code.used} className='form-control' disabled>
                                            <option value="false">NO</option>
                                            <option value="true">YES</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label className='form-label'>Amount</label>
                                        <input defaultValue={order.promo_code.total.toFixed(2)} type="text" className='form-control' disabled />
                                    </div>
                                    <div className="col-md-4">
                                            <label className='form-label'>Code</label>
                                            <input defaultValue={order.promo_code.code} type="text" className='form-control' disabled />
                                        </div>
                                </div>
                            </>
                        : null}
                        <hr />
                        {order.hasOwnProperty('coupon')?
                            <>
                                <div className="row mb-2">
                                    <div className="col-md-4">
                                        <label className='form-label'>Coupon</label>
                                        <select defaultValue={order.coupon.used} className='form-control' disabled>
                                            <option value="false">NO</option>
                                            <option value="true">YES</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label className='form-label'>Amount</label>
                                        <input defaultValue={order.coupon.total.toFixed(2)} type="text" className='form-control' disabled />
                                    </div>
                                    <div className="col-md-4">
                                        <label className='form-label'>Discount Type</label>
                                        <input defaultValue={order.coupon.discountType} type="text" className='form-control' disabled />
                                    </div>
                                </div>
                                {order.coupon.used?
                                <>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className='form-label'>Code</label>
                                            <input defaultValue={order.coupon.code} type="text" className='form-control' disabled />
                                        </div>
                                        <div className="col-md-4">
                                            <label className='form-label'>Coupon Type</label>
                                            <input defaultValue={order.coupon.couponType} type="text" className='form-control' disabled />
                                        </div>
                                    </div>
                                </>
                                : null}
                            </>
                        : null}
                    </div>
                    <div className='tab-pane' id={`shipday_${order['_id']}`}>
                        {order['meta_data'].hasOwnProperty('create_order_ship_day_resp')?
                            <div>
                                <span>ShipDay Order Creation Response</span>
                                <textarea defaultValue={order['meta_data']['create_order_ship_day_resp']} disabled className='form-control' cols="30" rows="5"></textarea>
                            </div>
                        : null}
                        <hr />
                        {order['meta_data'].hasOwnProperty('ship_day_notes')?
                            <div>
                                <span>ShipDay Completed Delivery Notes</span>
                                <textarea defaultValue={order['meta_data']['ship_day_notes']} disabled className='form-control' cols="30" rows="10"></textarea>
                            </div>
                        : 
                            <>
                                <h3 className='text-center opacity-60'>No ShipDay Notes Found</h3>
                                <div className='d-flex justify-content-center'>
                                    <button onClick={() => {onReSyncSdNotesClick()}} disabled={btnResyncSdText !== baseReSyncSdNotesText} className='btn btn-outline-info'>{btnResyncSdText}</button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
                {/* <button className="btn btn-sm btn-secondary" onClick={() => onHide('close', '')}>Close</button> */}
                <button onClick={() => onRemoveClick()} className="btn btn-sm btn-outline-danger">Remove</button>
                {isNewOrder(order) === true ? <button onClick={() => onCreateClick()} className="btn btn-sm btn-primary">Create</button> : null}
                {isNewOrder(order) === false ? <button onClick={() => onUpdateClick()}  className="btn btn-sm btn-primary">Update</button> : null}
            </Modal.Footer>
        </Modal>
    :null}

    <ConfirmAddOrderToShipday
        show={showConfirmAddOrderToShipday}
        params={confirmAddOrderToShipdayParams}
        onResp={(_cmd, _value) => onConfirmAddOrderToShipdayResp(_cmd, _value)}
    />
</>
)};

export default OrderModal